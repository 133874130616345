import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import WorkUnitOverview from "../../components/workUnitOverview"
import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"
import { Link } from "gatsby"

import botmVideo from "../../assets/botm.mp4"
import ReactPlayer from "react-player"

import * as botmStyles from "./botm.module.css"

const query = graphql`
  {
    allFile(filter: { name: { eq: "BOTM" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

const Botm = () => {
  const data = useStaticQuery(query)
  const botmImage = data.allFile.nodes[0].childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Boots On The Moon" />
      <section className="container">
        <Link to="/work" className={botmStyles.link}>
          <div className={botmStyles.backContainer}>
            <hr className={botmStyles.line} />
            <p className={botmStyles.back}>return to overview</p>
          </div>
        </Link>
        <WorkUnitOverview
          company="Boots On The Moon"
          image={botmImage}
          description="For six weeks, we co-founded our own pop-up creative agency at the Belgian Advertising School."
          page="workdetail"
        />
        <br />
        <br />
        <h3 className="subtitle">The situation.</h3>
        <p className="description">
          The last project for the Belgian Advertising School was to start our
          own creative agency Boots On The Moon. During these six weeks I
          learned a lot about the functioning of and roles within an advertising
          agency. I made an animation that shows our offer to potential
          customers.
        </p>
        <div className={botmStyles.playerWrapperSquare}>
          <ReactPlayer
            url={botmVideo}
            controls={true}
            width="100%"
            height="100%"
            pip={true}
            className={botmStyles.reactPlayer}
          />
        </div>
      </section>
    </Layout>
  )
}

export default Botm
